<template>
    <div class="feedback-list">
        <p class="head-title" v-if="arrList.length != 0"><img src="@/assets/img/feedback-head-icon.png" alt="">{{$t("feedback.desctitle")}}</p>
        <div class="list-item" v-for="(item,index) in arrList" :key="index">
            <div class="title-time" :class="{'replied':item.feedbackStatus != 144001}">
                <p class="">
                    <img class="icon" src="@/assets/img/blood-pressure-time-icon.png" >
                    <span class="time">{{$t("feedback.feedbackTime")}}：{{getLocalMinutesTime(item.feedbackCdt)}}</span>
                </p>
                <span class="feedbackStatus">
                    {{item.feedbackStatus == 144001?$t("feedback.toBeReplied"):$t("feedback.replied")}}
                </span>
            </div>
            <div class="emtry-hr"></div>
            <div class="item-container">
                <span class="lable">{{$t("feedback.content")}}：</span>
                <div class="container-box">
                    <p class="desc">{{item.postText}}</p>
                    <div class="item-img" v-if="item.postImgUrls&&item.postImgUrls.length != 0">
                        <span :style="'background-image:url('+url+');'" v-for="(url,cindex) in item.postImgUrls" @click="seeImg(index,cindex)" :key="cindex"></span>
                    </div>
                    <div class="replied-btn" v-if="item.feedbackStatus == 144002" @click="seeReply(item)">
                        {{$t('feedback.seeReply')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="list-emtry" v-if="arrList.length == 0">
            <img src="@/assets/img/data-null.png" >
            <p class="txt">{{$t("my.noFeedback")}}</p>
            <div class="add-btn" @click="goFeedback">{{$t("feedback.addfeedback")}}</div>
        </div>
        <div class="addSubscribe" v-if="arrList.length != 0" @click="goFeedback">
            <van-icon name="plus" />
        </div>
        <my-overlay :show="replyShow" :showField="'replyShow'">
           <div slot="overBox" class="reply-over">
               <p class="title">
                   {{$t("feedback.ideaReply")}}
                   <van-icon name="cross" @click="replyShow = false" />
               </p>
               <div class="reply-info">
                   <div class="info-item">
                        <span class="info-label">{{$t("feedback.replyTime")}}：</span>
                        <p class="info-value">{{getLocalMinutesTime(replyData.responseDt)}}</p>
                   </div>
                    <div class="info-item">
                        <span class="info-label">{{$t("feedback.replyContent")}}：</span>
                        <p class="info-value">{{replyData.responseText||$t("feedback.noReply")}}</p>
                   </div>
               </div>
           </div>
        </my-overlay>
    </div>
</template>

<script>
import { feedbackList } from "@/api/index";
import myOverlay from "@/components/myoverlay"
import { getLocalMinutesTime } from "@/utils/date"
import { ImagePreview } from 'vant';
export default {
    components:{myOverlay},
    data () {
        return {
            arrList:[],
            getLocalMinutesTime,
            replyShow:false,
            replyData:{}
        }
    },
    created () {
        this.getFeedback()
        document.title = this.$t("feedback.name")
    },
    methods:{
        getFeedback(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            feedbackList().then(res=>{
                toast.clear();
                if(res.data.errorCode == 0){
                    this.arrList = res.data.data;
                }else{

                }
            })
        },
        goFeedback(){
            this.$router.push({
                path: '/feedback/add',  
            })
        },
        // 查看回复
        seeReply(item){
            this.replyData = item;
            this.replyShow = true;
        },
        seeImg(index,cindex){
            ImagePreview({
                images: this.arrList[index].postImgUrls,
                startPosition: cindex,
            });
        }
    }
}
</script>

<style lang="less" scoped>
.feedback-list{
    height: 100%;
    overflow: auto;
    padding: 0 0.6rem;
    background: #f5f5f5;
    .addSubscribe{
        position: fixed;
        width: 1.92rem;
        height: 1.92rem;
        border-radius: 50%;
        background: #298AFB;
        right: 0.6rem;
        bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .van-icon-plus{
            color: #fff;
            font-size: 0.8rem;
            font-weight: bold;
        }
    }
    .head-title{
        height: 2rem;
        display: flex;
        align-items: center;
        font-size: 0.56rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        img{
            width: 0.92rem;
            height: 0.88rem;
            margin-right: 0.25rem;

        }   
    }
    .list-item{
        background: #fff;
        border-radius: 0.1rem;
        margin-bottom: 0.6rem;
        .title-time{
            padding: 0 0.6rem;
            display: flex;
            align-items: center;
            height: 1.9rem;
            position: relative;
            background: linear-gradient(180deg, #FFF6DE 0%, #FFFFFF 100%);
            .icon{
                width: 0.52rem;
                height: 0.56rem;
                margin-right: 0.2rem;
                vertical-align: text-bottom;
            }
            .time{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
            .feedbackStatus{
                position: absolute;
                right: 0;
                top: 0;
                font-size: 0.56rem;

                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFC00F;
                width: 2.3rem;
                line-height: 1rem;
                text-align: center;
                height: 1rem;
                background: #FFF4D6;
                box-shadow: 0 0.03rem 0.03rem #FFC00F;
                // border: 1px solid;
                // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 192, 15, 1)) 1 1;
                border-radius: 0 0.16rem 0 0.56rem;
            }
        }
        .replied{
            background: linear-gradient(180deg, #E8F4FF 0%, #FFFFFF 100%);
            .feedbackStatus{
                color: #49A7FF;
                background: #E0F0FF;
                box-shadow: 0 0.03rem 0.03rem #49A7FF;
            }
        }
        .emtry-hr{
            margin: 0 0.6rem;
            border-top:0.03rem dashed #DADADA;
            position: relative;
            &::after{
                position: absolute;
                content: ' ';
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background: #F6F7FB;
                left: -0.8rem;
                top: 50%;
                transform: translate(0, -50%);
            }
            &::before{
                position: absolute;
                content: ' ';
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background: #F6F7FB;
                right: -0.8rem;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        .item-container{
            padding: 0.6rem;
            display: flex;
            align-content: flex-start;
            font-size: 0.64rem;
            line-height: 0.9rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
            .lable{
                flex: 0 0 auto;
            }
            .container-box{
                flex: 1;
                .desc{

                }
                .item-img{
                    display: flex;
                    flex-wrap: wrap;
                    span{
                        width: 2.4rem;
                        height: 2.4rem;
                        border-radius: 0.16rem;
                        margin-right: 0.2rem;
                        margin-bottom: 0.2rem;
                        background-size: auto 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
                .replied-btn{
                    width: 3.4rem;
                    height: 1.2rem;
                    margin-top: 0.44rem;
                    line-height: 1.2rem;
                    text-align: center;
                    background: #49A7FF;
                    border-radius: 0.16rem;
                    font-size: 0.64rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }

    }
    .list-emtry{
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 8rem;
        }
        .txt{
            font-size: 0.56rem;
        }
        .add-btn{
            height: 1.8rem;
            width: 12.8rem;
            margin: 1rem auto;
            background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
            border-radius: 0.9rem;
            color: #fff;
            font-size: 0.72rem;
            line-height: 1.8rem;
            text-align: center;
        }
    }
}
.reply-over{
    background: #fff;
    border-radius: 0.6rem 0.6rem 0 0;
    .title{
        text-align: center;
        line-height: 2rem;
        height: 2rem;
        font-size: 0.72rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
        position: relative;
        .van-icon-cross{
            font-size: 0.64rem;
            color: #303133;
            position: absolute;
            right: 0.6rem;
            top: 50%;
            transform: translate(0,-50%);
            padding: 0.1rem;
        }
    }
    .reply-info{
        height: 50vh;
        padding: 0 0.6rem;
        overflow: auto;
        .info-item{
            display: flex;
            font-size: 0.64rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
            line-height: 1rem;
            margin-bottom: 0.3rem;
            .info-label{
                flex: 0 0 auto;
            }
            .info-value{
                flex: 1;
                word-break: break-all;
            }
        }
    }
}
</style>